import { creationInputAtom, enableAfcAtom } from '@/atoms'
import { CreationModeEnum, TemplateDetail, TemplateInputWidget, TemplateSettingWidget } from '@/types'
import { useAtom, useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useCachedSwitches } from './useSwitches'
import useActiveTemplate from './useActiveTemplate'
import { isProduction, isStage } from '@/utils'

const useCreationSettingVisibility = () => {
  const [{ mode }] = useAtom(creationInputAtom)
  const { data: switches } = useCachedSwitches()
  const { data: activeTemplate } = useActiveTemplate()

  const templateSettings: TemplateSettingWidget[] = useMemo(() => {
    return (activeTemplate as TemplateDetail)?.settings ?? []
  }, [activeTemplate])

  const showMotionLevel =
    [CreationModeEnum.Create, CreationModeEnum.Animate].includes(mode as any) ||
    templateSettings.some((widget) => widget.key === 'motion_level')

  const showDuration =
    [CreationModeEnum.Create, CreationModeEnum.Animate, CreationModeEnum.CreateHD, CreationModeEnum.AnimateHD].includes(
      mode as any,
    ) || templateSettings.some((widget) => widget.key === 'duration')

  const showResolution =
    [
      CreationModeEnum.CreateImg,
      CreationModeEnum.Create,
      CreationModeEnum.Animate,
      CreationModeEnum.CreateHD,
      CreationModeEnum.AnimateHD,
    ].includes(mode as any) || templateSettings.some((widget) => widget.key === 'resolution')

  const showAspectRatio =
    [CreationModeEnum.CreateHD, CreationModeEnum.CreateImg].includes(mode as any) ||
    templateSettings.some((widget) => widget.key === 'aspect_ratio')

  const showStyles =
    [CreationModeEnum.Create, CreationModeEnum.CreateHD, CreationModeEnum.CreateImg].includes(mode as any) ||
    !!activeTemplate?.styles?.length

  const enableAfc = useAtomValue(enableAfcAtom)

  const showCameraMovement =
    !!switches?.camera_movement &&
    ([CreationModeEnum.CreateHD, CreationModeEnum.Extend].includes(mode as any) ||
      (CreationModeEnum.AnimateHD === mode && !enableAfc))

  const showSeed = true

  const validKeys: string[] = useMemo(() => {
    return [
      showMotionLevel && 'motion_level',
      showDuration && 'duration',
      showResolution && 'resolution',
      showAspectRatio && 'aspect_ratio',
      showSeed && 'seed',
    ].filter(Boolean) as string[]
  }, [showMotionLevel, showDuration, showResolution, showAspectRatio, showSeed])

  const showFastGen = !isProduction || isStage
  const showTemplateClient =
    (!isProduction || isStage) &&
    !!(activeTemplate as TemplateDetail)?.template_client_name &&
    !!switches?.template_client

  return {
    showSeed,
    showMotionLevel,
    showDuration,
    showResolution,
    showAspectRatio,
    showCameraMovement,
    showStyles,
    showFastGen,
    showTemplateClient,
    validKeys,
  }
}

export default useCreationSettingVisibility
