import Button, { ButtonProps } from '@/components/button'
import { cls } from '@/utils'
import { forwardRef, useMemo } from 'react'
import { CreationInputAtom, GenerationSetting } from '@/types'
import useCredit from '@/hooks/useCredit'
import { useAtomValue, useSetAtom } from 'jotai'
import { activeTemplateIdAtom, insufficientDialogOpenAtom } from '@/atoms'
import IconClockTimeHistory from '@haiper/icons-svg/icons/solid/clock-time-history.svg'
import IconDiamond from '@/public/assets/diamond.svg'

export const ButtonGenerate = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    settings: GenerationSetting
    creationInput?: CreationInputAtom
    hideFreePrice?: boolean
  }
>(({ className, settings, creationInput, hideFreePrice, ...props }, ref) => {
  const activeTemplateId = useAtomValue(activeTemplateIdAtom)

  const { varifyCredit, getCreditPrice } = useCredit()
  const setInsufficientOpen = useSetAtom(insufficientDialogOpenAtom)
  const currentSku = useMemo(() => {
    return getCreditPrice({
      mode: creationInput?.mode || '',
      duration: settings?.duration || 0,
      resolution: settings?.resolution || 0,
    })
  }, [settings, creationInput?.mode, getCreditPrice])

  const handleClick = (e: any) => {
    if (varifyCredit(currentSku)) {
      props.onClick && props.onClick(e)
    } else {
      setInsufficientOpen(true)
    }
  }

  const icon = !!activeTemplateId ? (
    <IconClockTimeHistory className='size-4 text-[#FFC107]' />
  ) : (
    <IconDiamond className='size-4' />
  )

  return (
    <Button
      ref={ref}
      variant='primary'
      className={cls(
        'flex h-9 px-3 py-2 border-b-[3px] border-solid border-surface-primary-hover min-w-[88px]',
        className,
      )}
      {...props}
      onClick={handleClick}
    >
      <div className='flex items-center gap-2'>
        <span className='pl-1'>Create</span>
        {hideFreePrice && currentSku === 'Free' ? null : (
          <div className='bg-black-opacity-15 h-5 flex px-1 gap-1 items-center rounded-sm text-body-sm'>
            {icon}
            {currentSku}
          </div>
        )}
      </div>
    </Button>
  )
})

ButtonGenerate.displayName = 'ButtonGenerate'
