import { getTemplates } from '@/service/template.service'
import useService from '@banyudu/use-service'
import useInfinite from './useInfinite'
import { useAuthedCachedService } from './useAuthService'
import { Pagination, Template } from '@/types'

const validator = (params: Parameters<typeof getTemplates>[0]) => Boolean(params?.category)
const useTemplates = useService(getTemplates, validator)
export const useCachedTemplates = useAuthedCachedService(getTemplates, validator)

const getAllTemplates = async () => {
  const res = await getTemplates({ limit: 999999 })
  return res
}

export const useCachedAllTemplates = useAuthedCachedService<Pagination<Template>>(getAllTemplates)

export const useInfiniteTemplates = useInfinite(useTemplates, {
  rowKey: 'template_id',
})
