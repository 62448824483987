import { cls, getMailToLink } from '@/utils'
import { PropsWithChildren, useMemo } from 'react'
import qs from 'qs'

export interface MailToProps {
  className?: string
  email: string
  subject?: string
  body?: string
}

export default function MailTo({ className, email, subject, body }: MailToProps) {
  const text = useMemo(() => {
    return email.replace('mailto:', '')
  }, [email])

  const linkHref = useMemo(() => {
    return getMailToLink({ email, subject, body })
  }, [email, subject, body])

  return (
    <a
      href={linkHref}
      target='_blank'
      rel='noopener noreferer'
      className={cls('px-1 text-text-interactive hover:opacity-80 hover:no-underline inline-flex', className)}
    >
      {text}
    </a>
  )
}
